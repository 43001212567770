<template>
    <div>
        {{ brasindiceProps.item }} {{ brasindiceProps.apres }}
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        brasindiceProps: {
            type: Object,
            required: true
        }
    },
}
</script>
<style lang="scss" scoped>

</style>